import React from "react";
import Classes from "./faq.module.scss";
import LandingLayout from "../layout/LandingLayout";
import { Container } from "react-bootstrap";
import { Col, Collapse } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { BiDonateHeart } from "react-icons/bi";
import { AiOutlineGlobal, AiOutlineUsergroupAdd } from "react-icons/ai";
const { Panel } = Collapse;

function FAQ() {
  return (
    <LandingLayout>
      <Container>
        <div className={`${Classes.faq} pt-5`}>
          <h1 className="main_heading pt-5">
            Frequently Asked Questions (FAQs)
          </h1>
          <p>The Premier Charity NFT Marketplace for the nonprofit community</p>
          <div className="mt-4">
            <Collapse
              bordered={false}
              defaultActiveKey={["1"]}
              expandIcon={({ isActive }) => (
                <CaretRightOutlined rotate={isActive ? 90 : 0} />
              )}
              className="site-collapse-custom-collapse"
            >
              <Panel
                header={
                  <span className="d-flex align-items-center gap-2">
                    What is an NFT?
                  </span>
                }
                key="1"
                className="site-collapse-custom-panel"
              >
                <div className="px-2">
                  <p className="mb-2">
                    NFT stands for “non-fungible token,” which is a unit of data
                    capable of being owned and offered for exchange on a
                    blockchain according to its unique identifier. Every token
                    transaction involving the unique identifier creates a
                    permanent record on the blockchain which serves as a kind of
                    digital ledger. NFTs can be 2D, 3D, GIFs, video, text, and
                    even sound-based. You get to choose the digital medium that
                    best represents the idea or project you are sharing!
                  </p>
                  <p>
                    Many NFTs are unique creations while others represent
                    something more tangible. They relate to different aspects of
                    the continuum that exists between our physical and digital
                    lives. Creators must always ensure they own the intellectual
                    property rights used to create an NFT in the same way they
                    would need to with any other form of representation.
                  </p>
                </div>
              </Panel>
              <Panel
                header={
                  <span className="d-flex align-items-center gap-2">
                    What are the benefits of CharityNFT for a nonprofit
                    organization?
                  </span>
                }
                key="2"
                className="site-collapse-custom-panel"
              >
                <div className="px-2">
                  <p className={Classes.infotxt}>
                    The premier Charity NFT Marketplace for the nonprofit
                    community
                  </p>
                  <ul>
                    <li>
                      <b>Diversify Revenue Streams:</b> Nonprofits can accept
                      donations in Bitcoin, Ethereum, and other cryptocurrencies
                      through NFTs.
                    </li>
                    <li>
                      <b>Visibility and Collaboration:</b> Partnering with
                      creators and brands raises your organization’s profile
                      among crypto enthusiasts.
                    </li>
                    <li>
                      <b>Sustainable Fundraising Platform:</b> Use creative
                      appeals to generate repeat contributions from highly
                      motivated donors
                    </li>
                    <li>
                      <b>Cutting-Edge Technology:</b> NFTs offer an innovative
                      way for nonprofits to champion causes and fulfill their
                      mission.
                    </li>
                  </ul>
                </div>
              </Panel>
              <Panel
                header={
                  <span className="d-flex align-items-center gap-2">
                    What are the benefits of CharityNFT for an individual user?
                  </span>
                }
                key="3"
                className="site-collapse-custom-panel"
              >
                <div className="px-2">
                  <p className={Classes.infotxt}>
                    A direct donation Web3 model for charitable giving
                  </p>
                  <ul>
                    <li>Collect NFTs relating to causes you care about.</li>
                    <li>
                      Track your impact and stay engaged with your favorite
                      nonprofits.
                    </li>
                    <li>
                      Donate sales proceeds from NFTs you create or sell
                      directly to nonprofits.
                    </li>
                    <li>
                      Build your own personal philanthropic portfolio by
                      collecting NFTs across a range of causes.
                    </li>
                  </ul>
                </div>
              </Panel>
              <Panel
                header={
                  <span className="d-flex align-items-center gap-2">
                    What are some examples of NFTs being used for nonprofit
                    purposes?
                  </span>
                }
                key="4"
                className="site-collapse-custom-panel"
              >
                <div className="px-2">
                  <ul>
                    <li>
                      Woodies NFT, a project developed by Trees for the Future,
                      sells out 10 minutes after launch, raising one-third its
                      annual individual donations. (September 19, 2021)
                    </li>
                    <li>
                      Rolls Royce raises $1M for charity through donated NFTs
                      matched to their new Phantom Series II automobiles.
                      (November 9, 2022)
                    </li>
                    <li>
                      Fidelity Charitable offers 50 “golden ticket” NFTs
                      enabling purchasers to recommend $1,000 in donations to
                      support charities of their own choosing. (November 16,
                      2022)
                    </li>
                    <li>
                      50 artists work together alongside{" "}
                      <a
                        target="_blank"
                        href="https://crypto.com/"
                        rel="noreferrer"
                      >
                        crypto.com
                      </a>{" "}
                      to create NFTs in support of local relief and humanitarian
                      efforts in Turkey after a series of devastating
                      earthquake. (February 21, 2023)
                    </li>
                  </ul>
                </div>
              </Panel>
              <Panel
                header={
                  <span className="d-flex align-items-center gap-2">
                    What does it mean to “mint” an NFT?
                  </span>
                }
                key="5"
                className="site-collapse-custom-panel"
              >
                <div className="px-2">
                  <p className="">
                    Minting an NFT involves the creation of a smart contract,
                    the details of which can be stored on a blockchain. Creators
                    can mint a wide variety of NFT types and offer them for sale
                    on NFT exchanges like Charity NFT. One an NFT is minted, it
                    cannot be altered. Purchasers may be interested in the
                    causes that the NFT represents, its aesthetic quality, and
                    many other determinants of potential value. Since minting
                    can be energy-intensive, CharityNFT is always on the lookout
                    for ways to ensure that energy requirements and associated
                    fees are kept as low as possible for each transaction.
                  </p>
                </div>
              </Panel>
              <Panel
                header={
                  <span className="d-flex align-items-center gap-2">
                    What is a blockchain?
                  </span>
                }
                key="6"
                className="site-collapse-custom-panel"
              >
                <div className="px-2">
                  <p className="mb-2">
                    Blockchains are a kind of ledger for digital transactions
                    which can be connected to digital wallets. They are
                    typically distributed in a decentralized fashion across many
                    different computers using peer-to-peer networks to ensure
                    the integrity of data. Blockchains use different
                    technologies like Proof-of-Work, Proof-of-History, or
                    Proof-of- Stake to validate transactions, and each comes
                    with its own set of fees or requirements.
                  </p>
                  <p>
                    CharityNFT selected the Polygon technology platform using
                    the Proof-of-Stake Ethereum blockchain. Ethereum is the most
                    commonly used secure blockchain, but it often has high
                    transaction fees and slow transaction speeds. As a Layer2
                    platform, Polygon facilitates transactions on Ethereum with
                    the native cryptocurrency POL to keep the transaction’s
                    gas fees low and speed up processing.
                  </p>
                </div>
              </Panel>
              <Panel
                header={
                  <span className="d-flex align-items-center gap-2">
                    How do I create a wallet?
                  </span>
                }
                key="7"
                className="site-collapse-custom-panel"
              >
                <div className="px-2">
                  <p className="mb-2">
                    You will need to connect a crypto wallet to your CharityNFT
                    account in order to make transactions. These wallets encrypt
                    and store your NFTs. We currently only support connections
                    to Metamask and Coinbase wallets.
                  </p>
                  <p className="mb-2">
                    For Metamask, please click here:{" "}
                    <a
                      target="_blank"
                      href="https://metamask.io/"
                      rel="noreferrer"
                    >
                      https://metamask.io/
                    </a>
                  </p>
                  <p>
                    For Coinbase, please click here:{" "}
                    <a
                      target="_blank"
                      href="https://www.coinbase.com/"
                      rel="noreferrer"
                    >
                      https://www.coinbase.com/
                    </a>
                  </p>
                </div>
              </Panel>
              <Panel
                header={
                  <span className="d-flex align-items-center gap-2">
                    What are the steps to creating and selling an NFT using the
                    CharityNFT nonprofit marketplace?
                  </span>
                }
                key="8"
                className="site-collapse-custom-panel"
              >
                <div className="px-2">
                  <ul>
                    <li>
                      <b>Step 1:</b> Register your account with CharityNFT,
                      connect your cryptocurrency wallet, and familiarize
                      yourself with the platform.
                    </li>
                    <li>
                      <b>Step 2:</b> Find an NFT artist or collaborator to help
                      in the creative design process if needed.
                    </li>
                    <li>
                      <b>Step 3.</b> Decide whether the sale will be fixed price
                      or an auction and what the percentage level will be for
                      the charitable donation if applicable.
                    </li>
                    <li>
                      <b> Step 4.</b> Settle on the number of NFTs you plan to
                      offer and the most applicable categories.
                    </li>
                    <li>
                      <b> Step 5.</b> Identify the date and duration of your
                      planned sale when ready and inform possible donors about
                      it.
                    </li>
                    <li>
                      <b>Step 6:</b> Create your NFT or NFT collection at
                      CharityNFT by uploading your media files.
                    </li>
                    <li>
                      <b>Step 7:</b> Be sure to add any relevant hashtags to the
                      NFT to improve visibility to other users
                    </li>
                  </ul>
                </div>
              </Panel>
              <Panel
                header={
                  <span className="d-flex align-items-center gap-2">
                    How do I prepare for my first NFT Fundraiser?
                  </span>
                }
                key="9"
                className="site-collapse-custom-panel"
              >
                <div className="px-2">
                  <p>
                    Organizations should focus on the strategy they plan to use
                    for their NFT fundraiser. Are they looking to sell
                    membership badges or integrate the NFT more fully into their
                    project goal as a functional component? After determining
                    the strategic vision, many nonprofits will partner with an
                    NFT artist or collaborator to create their NFT project.
                    Leading brands may also be interested in partnering on your
                    NFT project as part of their Corporate Social Responsibility
                    (CSR) efforts.
                  </p>
                </div>
              </Panel>
              <Panel
                header={
                  <span className="d-flex align-items-center gap-2">
                    How do I donate by selling an NFT?
                  </span>
                }
                key="10"
                className="site-collapse-custom-panel"
              >
                <div className="px-2">
                  <p>
                    CharityNFT is designed to promote everyday individual giving
                    by all types of people. We encourage people to find causes
                    and use their creativity to benefit those causes no matter
                    how much money they may have in their bank account. To
                    accomplish this, users just need to mint an NFT of their own
                    creation, identify a nonprofit or fiscal project to support,
                    and specify the amount of the sales proceeds that will be
                    donated to them. Everything else will be handled by the
                    smart contract at the point of sale.
                  </p>
                </div>
              </Panel>
              <Panel
                header={
                  <span className="d-flex align-items-center gap-2">
                    How do I see the portfolio of causes that I support?
                  </span>
                }
                key="11"
                className="site-collapse-custom-panel"
              >
                <div className="px-2">
                  <p>
                    CharityNFT users can quickly see their portfolio of causes
                    by clicking on the “My Causes” tab in their profile page. We
                    encourage users to think about their NFT collection as a
                    true philanthropic portfolio so they can see their giving
                    patterns.
                  </p>
                </div>
              </Panel>
              <Panel
                header={
                  <span className="d-flex align-items-center gap-2">
                    How do I connect with other users and see their NFT
                    collection?
                  </span>
                }
                key="12"
                className="site-collapse-custom-panel"
              >
                <div className="px-2">
                  <p>
                    Users can use the Friends list to connect with other users
                    on the platform. This feature allows you to follow and
                    communicate with like-minded users. Friends are able to see
                    the NFT collections built up by each other. CharityNFT helps
                    you find friends by highlighting the number of shared causes
                    between users. The leaderboard is also a useful tool to see
                    what other users are doing on the platform.
                  </p>
                </div>
              </Panel>
              <Panel
                header={
                  <span className="d-flex align-items-center gap-2">
                    Can I share my social media links on CharityNFT?
                  </span>
                }
                key="13"
                className="site-collapse-custom-panel"
              >
                <div className="px-2">
                  <p>
                    Of course! CharityNFT users can enter their social media
                    links in their profile settings.
                  </p>
                </div>
              </Panel>
              <Panel
                header={
                  <span className="d-flex align-items-center gap-2">
                    How do I file a complaint about another user or
                    organization?
                  </span>
                }
                key="14"
                className="site-collapse-custom-panel"
              >
                <div className="px-2">
                  <p>
                    CharityNFT users can file complaints by clicking on the flag
                    icon in the profile of an organization or another user.
                    These complaints are reviewed by our administrative team.
                  </p>
                </div>
              </Panel>
              <Panel
                header={
                  <span className="d-flex align-items-center gap-2">
                    How can I share or display my NFT portfolio?
                  </span>
                }
                key="15"
                className="site-collapse-custom-panel"
              >
                <div className="px-2">
                  <p>
                    Each profile has a unique URL that they can share. Each NFT
                    and NFT collection also has their unique URL.
                  </p>
                </div>
              </Panel>
              <Panel
                header={
                  <span className="d-flex align-items-center gap-2">
                    How do I report a donation from the sale of an NFT on my
                    taxes in the United States?
                  </span>
                }
                key="16"
                className="site-collapse-custom-panel"
              >
                <div className="px-2">
                  <p className="mb-2">
                    NFTs and cryptocurrency are treated as property from a tax
                    perspective. Donors seeking a tax deduction in the U.S. are
                    required to complete IRS Form 8283 (Noncash Charitable
                    Contributions) in any year where a total deduction of over
                    $500 is claimed for all contributed property{" "}
                    <a
                      target="_blank"
                      href="https://www.irs.gov/pub/irs-pdf/f8283.pdf"
                      rel="noreferrer"
                    >
                      https://www.irs.gov/pub/irs-pdf/f8283.pdf
                    </a>
                    . In cases where a single nonprofit receives over $5,000
                    from a donor in a single year, they may be requested to sign
                    the donor’s IRS Form 8283 (Noncash Charitable
                    Contributions).
                  </p>
                  <p>
                    Your charitable deduction for federal income tax purposes is
                    limited to the excess of the amount of money contributed
                    over the value of the goods or services provided by the
                    charity. Please consult your tax or financial advisor to
                    ensure compliance with all applicable laws and regulations.
                  </p>
                </div>
              </Panel>
              <Panel
                header={
                  <span className="d-flex align-items-center gap-2">
                    How do I create an NFT Collection for a fundraising
                    campaign?
                  </span>
                }
                key="17"
                className="site-collapse-custom-panel"
              >
                <div className="px-2">
                  <p className="">
                    Nonprofits and other users can create collections of related
                    NFTs to help promote their causes. These collections can
                    improve sales by creating multiple points of interest for
                    buyers looking to help support the associated mission or
                    causes.
                  </p>
                </div>
              </Panel>
              <Panel
                header={
                  <span className="d-flex align-items-center gap-2">
                    What happens to my intellectual property rights when I
                    create and sell my NFT?
                  </span>
                }
                key="18"
                className="site-collapse-custom-panel"
              >
                <div className="px-2">
                  <p className="mb-2">
                    Creators of NFTs will typically transfer their ownership
                    rights in a sale but keep their intellectual property
                    rights. For this reason, it is vital to ensure that you as
                    the creator own the underlying intellectual property for any
                    NFT that you mint.
                  </p>
                  <p>
                    Royalty fees are often included in smart contracts to allow
                    creators to automatically receive rewards when their NFTs
                    are resold in the same marketplace where they were minted.
                  </p>
                </div>
              </Panel>
              <Panel
                header={
                  <span className="d-flex align-items-center gap-2">
                    What fees does CharityNFT charge?
                  </span>
                }
                key="19"
                className="site-collapse-custom-panel"
              >
                <div className="px-2">
                  <p className="">
                    CharityNFT charges a fee of 2.5% on each transaction.
                    Polygon charges a variable gas fee on each transaction as
                    well to provide support for the Layer 2 network. All fees
                    are detailed on each transaction receipt.
                  </p>
                </div>
              </Panel>
              <Panel
                header={
                  <span className="d-flex align-items-center gap-2">
                    What sets CharityNFT apart from other NFT exchanges?
                  </span>
                }
                key="20"
                className="site-collapse-custom-panel"
              >
                <div className="px-2">
                  <p className="mb-2">
                    CharityNFT is the premier NFT marketplace for the nonprofit
                    community. We enable users to contribute to the causes they
                    care most about by buying and selling NFTs with a portion of
                    the proceeds going to the benefit of registered 501(c)(3)
                    nonprofit organizations and their sponsored fiscal projects.
                    Anyone can participate in this way by leveraging their
                    creativity for the kind of change these organizations make
                    possible. Nonprofits can also use the platform to create
                    distinctive NFT fundraising campaigns of their own.
                  </p>
                  <p className="mb-2">
                    The sheer quantity of NFTs available on other exchanges
                    makes it nearly impossible for nonprofit organizations to
                    stand out from the crowd. We created CharityNFT in the
                    realization that the lack of a dedicated exchange made it
                    hard for nonprofits to utilize this new Web3 technology.
                  </p>
                  <p>
                    NFT philanthropy offers a reciprocal approach to charity
                    where both donors and nonprofits can feel a sense of reward
                    from the fundraising process. It gives donors large and
                    small an opportunity to invest in causes and methodically
                    track the impact of their giving strategies over time. By
                    minting NFTs for charity, we can all work together to build
                    a more meaningful economy.
                  </p>
                </div>
              </Panel>
              <Panel
                header={
                  <span className="d-flex align-items-center gap-2">
                    #CryptoGivingTuesday
                  </span>
                }
                key="21"
                className="site-collapse-custom-panel"
              >
                <div className="px-2">
                  <p className="">
                    Remember that #CryptoGivingTuesday is the first Tuesday each
                    year following Black Friday.
                  </p>
                </div>
              </Panel>
            </Collapse>
          </div>
        </div>
      </Container>
    </LandingLayout>
  );
}

export default FAQ;
