import React, {
  useState,
  useEffect,
  Fragment,
  // KeyboardEventHandler,
} from "react";
import CreatableSelect from "react-select/creatable";
import Classes from "./createNft.module.scss";
import { Col, Row, Select } from "antd";
import { Container } from "react-bootstrap";
import { ThreeDots } from "react-loader-spinner";
import * as Yup from "yup";
import { Formik } from "formik";
import CreateNftModal from "./CreateNftModal.jsx";
import { processNftToPinata, saveNFT } from "../../services/nft.service";
import UploadImg from "../../assets/images/svg/upload.svg";
import { Link, useNavigate } from "react-router-dom";
import addimg from "../../assets/images/svg/addBtn.svg";
import { alertMessage } from "../../constants/alertMessage";
import { collectionsListing } from "../../services/collections.service";

import { useSelector } from "react-redux";
import { FaTrash } from "react-icons/fa6";
import { decimalnumbervalidator, formatBytes } from "../../helpers/common";
import Select2 from "react-select";
import VideoComponent from "../../helpers/VideoPreview";
import useMintNft from "../../hooks/useMintNft.js";
import { ethers } from "ethers";
import NotificationAlert from "../Alert";
import { BiCopyAlt } from "react-icons/bi";
import CommonModal from "../views/Pages/CommonModal";


const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});

const mainCategories = [
  {
    label: "AI-Generated Media",
    value: "ai-generative-media",
  },
  {
    label: "Avatar",
    value: "avatar",
  },
  {
    label: "Digital Art",
    value: "digital art",
  },
  {
    label: "Event Ticket",
    value: "event-ticket",
  },
  {
    label: "Membership ID",
    value: "membership-id",
  },
  {
    label: "Music & Audio",
    value: "music-audio",
  },
  {
    label: "Photography",
    value: "photography",
  },
  {
    label: "Original & Unique Artwork (1/1)",
    value: "original-unique-artwork",
  },
  {
    label: "Text-Based Media",
    value: "text-based-media",
  },
  {
    label: "Video Clip & GIF",
    value: "video-clips-gifs",
  },
];
const { Option } = Select;
const CreateNftComp = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [nftFile, setNftFile] = useState("");
  const [allowedFileTypes, setAllowedFileTypes] = useState([
    "image/png",
    "image/jpeg",
    "image/jpg",
    "video/mp4",
    "image/svg+xml",
    "image/gif",
  ]);
  const [uploadedFileType, setUploadedFileType] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  const [nftPreviewName, setNftPreviewName] = useState("");
  const [nftImageValidation, setNftImageValidation] = useState("");
  const [categoryValidation, setCategoryValidation] = useState("");
  const [attributeValidation, setAttributeValidation] = useState("");
  const [royaltyFeeValidation, setRoyaltyFeeValidation] = useState("");
  const [collections, setCollections] = useState("");
  const [selectedCollection, setSelectedCollection] = useState("");
  const [createdNft, setCreatedNft] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [hashTags, setHashTags] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsValues, setSelectedOptionsValues] = useState([]);
  const [selectedPrimaryOption, setSelectedPrimaryOption] = useState("");
  const [selectedSecondaryOption, setSelectedSecondaryOption] = useState("");
  const [royaltyFeeInput, setRoyaltyFeeInput] = useState("");
  const [data, setData] = useState(null);
  const [pinataRes, setPinataRes] = useState(null);
  const auth = useSelector((state) => state?.auth);
  const platformSettings = useSelector(
    (state) => state?.platformSettings?.platformSettings
  );
  const { connectedAddress } = useSelector((state) => state.wallet);
  const [showAlertMessage, setShowAlertMessage] = useState("");
  const [isNftRestrictModalOpen, setIsNftRestrictModalOpen] = useState(false);
  const [nftRestrictMessage, setNftRestrictMessage] = useState("Please contact your fiscal sponsor to approve your profile so that you can mint NFTs and access all the features on this platform.");
  const [modalType, setModalType] = useState("Minting Prohibited");
  

  const navigate = useNavigate();

  const token = localStorage.getItem("authenticationToken");
  useEffect(() => {
    const { username, id } = auth || {};
    if (!username && !id) {
      navigate("/login");
    } else if (!username && id) {
      navigate("/get-started");
    }
  }, [auth]);

  const handleKeyDown = (event) => {
    if (!inputValue) return;
    switch (event.key) {
      case "Enter":
      case "Tab":
        setHashTags((prev) => [...prev, createOption(inputValue)]);
        setInputValue("");
        event.preventDefault();
    }
  };

  const handleProvinceChange = (value) => {
    const collection = collections.find((c) => c._id === value);
    setSelectedCollection(collection);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const getCollectionListing = async () => {
    try {
      const collectionsData = await collectionsListing(token);
      if (collectionsData) {
        setCollections(collectionsData?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const royaltyFee =
    (platformSettings && platformSettings[0]?.royaltyFee) || 2.5;

  useEffect(() => {
    if(auth?.verificationStatus === 'disapproved'){
      showNftRestrictModal()
    }
    getCollectionListing();
    setRoyaltyFeeInput(royaltyFee);

  }, []);
  const decodeTokenId = (encodedData) => {
    // Define the ABI types for the data you want to decode
    const abiTypes = ["uint256", "uint256"];

    // Decode the data
    const decodedData = ethers.utils.defaultAbiCoder.decode(
      abiTypes,
      encodedData
    );

    // Convert BigNumber objects to numbers
    const decodedNumbers = decodedData.map((bigNumber) => bigNumber.toNumber());
    return decodedNumbers[0];
  };

  const {
    mint,
    isNftCompleted,
    isNftTxInProgress,
    isNftWaiting,
    isNftTxSent,
    nftMintResp,
    isNftSentError,
    nftSentError,
    isNftError,
    nftTxError,
    nftSentTx,
  } = useMintNft();
  useEffect(() => {
    const handleMinting = async () => {
      const nftHashTags = [];
      hashTags &&
        hashTags?.map((tag) => {
          nftHashTags.push(tag?.value);
        });
      const tokenId = decodeTokenId(nftMintResp?.logs[0].data);
      const nftRequestData = {
        tokenId: tokenId,
        ownerAddress: connectedAddress,
        name: data?.name,
        file: pinataRes?.data?.data?.image,
        metaData: pinataRes?.data?.data?.dataInput?.metaDataUrl,
        description: data?.description,
        copies: data?.numberOfCopies,
        totalCopies: data?.numberOfCopies,
        royaltyFee: Math.ceil(Number(data.royaltyFee) * 100),
        collectionId: selectedCollection?._id,
        txHash: nftSentTx?.hash,
        userId: auth?.id,
        categoryId: selectedOptionsValues,
        ownerId: auth?.id,
        chainId: 80001,
        blockchainType: "polygon",
        hashTags: nftHashTags,
        primaryType: uploadedFileType,
        secondaryType:
          selectedSecondaryOption
            ?.map((item) => item.value)
            .join(selectedSecondaryOption.length > 1 ? "," : "") || "",
      };
      const commitNFT = await saveNFT(nftRequestData);
      if (commitNFT && commitNFT?.data?.message) {
        setCreatedNft(commitNFT.data.data);
        showModal();
        setIsLoading(false);
      }
    };
    if (isNftCompleted) {
      handleMinting();
    }
  }, [isNftCompleted]);
  useEffect(() => {
    if (isNftSentError || isNftError) {
      const error = nftSentError || nftTxError;
      if (error?.cause?.name === "UserRejectedRequestError") {
        setIsLoading(false);
        setShowAlertMessage(error?.cause?.shortMessage);
        return;
      }
      setIsLoading(false);
      alertMessage("error", error?.name || "Unknown error");
    }
  }, [isNftSentError, isNftError]);

  const mintNft = async (data) => {
    try {
      setShowAlertMessage("");
      setIsLoading(true);
      if (auth?.verificationStatus === 'disapproved') {
        showNftRestrictModal()
        setIsLoading(false);
        return;
      }
      if (!connectedAddress) {
        alertMessage("error", "Please connect your wallet.");
        setIsLoading(false);
        return;
      }
      if (!selectedCollection && collections?.length > 0) {
        alertMessage("error", "Please select a collection");
        setIsLoading(false);
        return;
      }
      if (!selectedCollection && !collections?.length) {
        alertMessage("error", "Please create a collection first");
        setIsLoading(false);
        return;
      }
      if (!nftFile) {
        setNftImageValidation("Nft file is required.");
        setIsLoading(false);
        return;
      }

      if (!selectedOptionsValues?.length) {
        setCategoryValidation("Causes are required.");
        setIsLoading(false);
        return;
      }

      if (!selectedSecondaryOption?.length) {
        setAttributeValidation("Main category is required.");
        setIsLoading(false);
        return;
      }
      // if (!selectedPrimaryOption) {
      //   setPrimaryTypeValidation("Primary type is required.");
      //   setIsLoading(false);
      //   return;
      // }

      // setPrimaryTypeValidation("");
      setRoyaltyFeeValidation("");
      setCategoryValidation("");
      setAttributeValidation("");
      setNftImageValidation("");
      const nftData = {
        file: nftFile,
        name: data?.name,
        description: data?.description,
        royaltyFee: data?.royaltyFee * 100,
        supply: data?.numberOfCopies,
        creatorAddress: connectedAddress
        
        // TODO: type:
      };

      const pinataResponse = await processNftToPinata(nftData);

      if (!pinataResponse?.data?.data?.dataInput && pinataResponse?.message) {
        alertMessage("error", `${pinataResponse?.message}`);
        setIsLoading(false);
        return false;
      }

      if (pinataResponse && pinataResponse?.data?.data?.dataInput) {
        setPinataRes(pinataResponse);
        let _fiscalSponsor;
        if (auth?.fiscalSponsorId) {
          _fiscalSponsor = auth?.fiscalSponsorId?.walletAddress;
        }
        alertMessage(
          "warning",
          "Please do not close or refresh page during transaction"
        );
        mint({
          args: [
            data?.numberOfCopies,
            pinataResponse?.data?.data?.dataInput?.metaDataUrl,
            Math.ceil(Number(data.royaltyFee) * 100),
            _fiscalSponsor || "0x0000000000000000000000000000000000000000",
          ],
          from: connectedAddress,
        });
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleImageUpload = (e) => {
    const file = e?.target?.files[0];
    const fileType = file?.type;

    const isFileTypeAllowed = allowedFileTypes.includes(fileType);

    if (isFileTypeAllowed === true) {
      if (!handleFilesValidation(e)) {
        return;
      }
      setNftImageValidation("");
      const file = e.target.files[0];
      const imageType = file?.type.split("/")[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (event) => {
          const uploadedImageUrl = event.target.result;
          setPreviewImage(uploadedImageUrl);
        };
        setUploadedFileType(imageType);
        setNftFile(file);
        reader.readAsDataURL(file);
      }
    } else {
      alertMessage("error", "Allowed file types are JPG, PNG, GIF, SVG, MP4");
    }
  };
  const handleFilesValidation = (e) => {
    let files;
    let type;
    // if (status === "onDrag") {
    //   files = e.dataTransfer.files[0];
    //   type = e.dataTransfer.files[0].type;
    // } else {
    files = e.target.files[0];
    type = e.target.files[0].type;
    // }
    if (files) {
      if (
        type === "image/jpeg" ||
        type === "image/png" ||
        type === "image/gif" ||
        type === "image/svg" ||
        type === "image/svg+xml" ||
        type === "image/webp" ||
        type === "image/jpg" ||
        type?.startsWith("video/")
      ) {
        const fileSize = formatBytes(files.size).split(" ");
        if (
          (+fileSize[0] > 50 && fileSize[1] === "MB") ||
          fileSize[1] === "GB"
        ) {
          setNftImageValidation("File size limit is exceeded");
          return false;
        } else {
          return true;
        }
      } else {
        setNftImageValidation("File not supported!");
        return false;
      }
    }
  };

  const handleCategoryChange = (selectedOptions) => {
    // Ensure the number of selected options does not exceed 3
    const ids = [];
    // if (selectedOptions.length <= 3) {
    selectedOptions?.map((selectedOption) => {
      ids?.push(selectedOption?.value);
    });

    setSelectedOptionsValues(ids);
    setSelectedOptions(selectedOptions);
    setCategoryValidation("");
   
  };

  const handleSecondaryType = (selectedOptions) => {
    if (selectedOptions) {
      setSelectedSecondaryOption(selectedOptions);
      setAttributeValidation("");
    }
  };

  const categoriesOptions =
  auth?.categories
    ?.map((category) => ({
      label: category?.categoryName,
      value: category?._id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label)) || [];

  const CustomPlaceholder = () => (
    <div className={Classes.collectionPlaceholder}>
      <div className={Classes.icon}>
        <BiCopyAlt />{" "}
      </div>
      <span>Choose a collection</span>
    </div>
  );
  const showNftRestrictModal = () => {
    setIsNftRestrictModalOpen(true);
  };
  const handleNftRestrictModalCancel = () => {
    setIsNftRestrictModalOpen(false);
  };
  return (
    <div className={Classes.createNft}>
      <Container>
        <Row gutter={[50, 20]}>
          <Col lg={7} md={24} sm={24} xs={24}>
            <span>
              <span className={Classes.title}>Create an NFT</span>
            </span>
            <p className={Classes.desc}>
              Once your item is minted you will not be able to change any of its
              information.
            </p>
            <div className={Classes.nftCard}>
              {previewImage ? (
                <div className="position-relative">
                  <div
                    className={Classes.actionBtn}
                    onClick={() => {
                      setPreviewImage("");
                      setNftFile(null);
                    }}
                  >
                    <FaTrash color="red" />
                  </div>

                  {nftFile?.type.includes("image/") ? (
                    <img src={previewImage} alt="img" />
                  ) : (
                    <VideoComponent source={previewImage} />
                  )}
                </div>
              ) : (
                <div
                  className={`${Classes.previewimg} d-flex justify-content-center align-items-center flex-column`}
                >
                  <p className={`${Classes.uploadCardText}`}>
                    Maximum size: 50MB
                  </p>
                  <p className={`${Classes.uploadCardText}`}>
                    JPG, PNG, GIF, SVG, MP4
                  </p>
                </div>
              )}
              <div className={Classes.cardInfo}>
                <div
                  className={`d-flex justify-content-between align-items-center mb-2`}
                >
                  <p className={Classes.price}>
                    {nftPreviewName ? (
                      nftPreviewName
                    ) : (
                      <span className={Classes.previewTxt}></span>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </Col>
          <Col lg={17} md={24} sm={24} xs={24}>
            <h3 className={Classes.title}>Upload file*</h3>
            <p className={Classes.desc}>Choose your file to upload</p>
            <div className="mb-5">
              <div className="position-relative">
                <div className="d-flex align-items-center gap-2">
                  <img src={UploadImg} alt="img" />
                  <p className={Classes.uploadTxt}>Upload your file</p>
                </div>
                <p className={`${Classes.desc} mt-1`}>
                  Maximum size 50 MB and file type (JPG, PNG, GIF, SVG, MP4)
                </p>

                <input
                  className={Classes.uploadInput}
                  type="file"
                  accept="image/png, image/jpeg, image/jpg, video/mp4, image/svg+xml, image/gif"
                  onChange={handleImageUpload}
                />
                <span
                  className="mt-1 d-block"
                  size={"small"}
                  style={{ color: "red" }}
                >
                  {nftImageValidation ? (
                    <Fragment>{nftImageValidation}</Fragment>
                  ) : null}
                </span>
              </div>
            </div>
            <Formik
              initialValues={{
                name: "",
                description: "",
                numberOfCopies: "",
                royaltyFee: royaltyFee,
              }}
              validationSchema={Yup.object().shape({
                name: Yup.string()
                  .trim()
                  .max(
                    35,
                    ({ max }) => `Name should not exceed ${max} characters.`
                  )
                  .required("Name is required."),
                description: Yup.string()
                  .trim()
                  .max(
                    500,
                    ({ max }) =>
                      `Description should not exceed ${max} characters.`
                  )
                  .required("Description is required."),
                numberOfCopies: Yup.number()
                  .min(1, "Supply must be greater than or equal to 1. ")
                  .max(
                    100000000000,
                    ({ max }) => `Supply should not exceed ${max}.`
                  )
                  .required("Supply is required."),
                royaltyFee: Yup.number()
                  .min(
                    1,
                    ({ min }) =>
                      `Royalty fee must be greater than or equal to 1 and less than or equal to 30`
                  )
                  .max(
                    30,
                    ({ max }) =>
                      `Royalty fee must be greater than or equal to 1 and less than or equal to 30`
                  )
                  .required("Royalty fee is required."),
              })}
              onSubmit={(values, { resetForm }) => {
                const data = {
                  name: values.name,
                  description: values.description,
                  numberOfCopies: values.numberOfCopies,
                  royaltyFee: values.royaltyFee,
                  creatorAddress: connectedAddress
                };
                mintNft(data);
                setData(data);
                // resetForm({ values: '' });
              }}
              textAlign="center"
            >
              {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                touched,
                values,
              }) => (
                <div className={Classes.form}>
                  <form
                    onSubmit={handleSubmit}
                    className="mt-8 space-y-6"
                    noValidate
                    autoComplete="off"
                  >
                    <div className="mb-3">
                      <div className={Classes.label}>Collection*</div>
                      {collections?.length ? (
                        <div className="mb-3 collectionSelect">
                          <Select
                            placeholder={<CustomPlaceholder />}
                            onChange={handleProvinceChange}
                            value={
                              selectedCollection
                                ? selectedCollection._id
                                : undefined
                            }
                            className={Classes.select}
                            // open={true}
                            // dropdownClassName="custom-collection-dropdown"
                          >
                            {collections?.map((collection) => (
                              <Option
                                key={collection._id}
                                value={collection._id}
                              >
                                <div className={Classes.option}>
                                  <img
                                    src={collection.collectionImage}
                                    alt={collection.collectionName}
                                    className={Classes.optionImage}
                                  />
                                  {collection.collectionName}
                                </div>
                              </Option>
                            ))}
                          </Select>

                          {/* Render selected collection's image and name */}
                          {/* {selectedCollection ? (
                            <div className={Classes.selectedCollection}>
                              <img
                                src={selectedCollection.collectionImage}
                                alt={selectedCollection.collectionName}
                                className={Classes.selectedImage}
                              />
                              <span>{selectedCollection.collectionName}</span>
                            </div>
                          ) : (
                            ""
                          )} */}
                        </div>
                      ) : (
                        ""
                      )}
                      <Link
                        className={`${Classes.createNftdiv} mb-3`}
                        to="/create-collection"
                      >
                        <img src={addimg} alt="img" />
                        <p>Create a new collection</p>
                      </Link>
                    </div>

                    <div className="mb-3">
                      <div className={Classes.label}>Causes*</div>
                      <Select2
                        placeholder="select causes"
                        style={{ marginTop: 10 }}
                        onChange={handleCategoryChange}
                        value={selectedOptions}
                        options={categoriesOptions}
                        isMulti
                      />
                      <span
                        className="mt-1 d-block"
                        size={"small"}
                        style={{ color: "red" }}
                      >
                        {categoryValidation ? (
                          <Fragment>{categoryValidation}</Fragment>
                        ) : null}
                      </span>
                    </div>
                    <div className="mb-3">
                      <div className={Classes.label}>Main categories*</div>
                      <Select2
                        placeholder="select categories"
                        style={{ marginTop: 10 }}
                        onChange={handleSecondaryType}
                        value={selectedSecondaryOption}
                        options={mainCategories}
                        isMulti
                      />
                      <span
                        className="mt-1 d-block"
                        size={"small"}
                        style={{ color: "red" }}
                      >
                        {attributeValidation ? (
                          <Fragment>{attributeValidation}</Fragment>
                        ) : null}
                      </span>
                    </div>

                    <div className="mb-3">
                      <div className={Classes.label}>Name*</div>
                      <input
                        className="pe-3"
                        type="text"
                        name="name"
                        onBlur={handleBlur}
                        // onChange={handleChange}
                        onChange={(e) => {
                          handleChange(e);
                          setNftPreviewName(e.target.value);
                        }}
                        value={values.name}
                        placeholder="e.g: Crypto Hunks"
                      />
                      <span
                        className="mt-1 d-block"
                        size={"small"}
                        style={{ color: "red" }}
                      >
                        {errors.name && touched.name ? (
                          <Fragment>{errors.name}</Fragment>
                        ) : null}
                      </span>
                    </div>
                    <div className="mb-3">
                      <div className={Classes.label}>Supply*</div>
                      <input
                        className="pe-3"
                        type="number"
                        name="numberOfCopies"
                        onBlur={handleBlur}
                        onChange={(e) => {
                          if (Number(e.target.value) < 0) {
                            return;
                          }
                          handleChange(e);
                        }}
                        onKeyDown={(e) => decimalnumbervalidator(e)}
                        value={values.numberOfCopies}
                        placeholder="e.g: 4"
                      />
                      <span
                        className="mt-1 d-block"
                        size={"small"}
                        style={{ color: "red" }}
                      >
                        {errors.numberOfCopies && touched.numberOfCopies ? (
                          <Fragment>{errors.numberOfCopies}</Fragment>
                        ) : null}
                      </span>
                    </div>
                    <div className="mb-3">
                      <div className={Classes.label}>Description*</div>
                      <textarea
                        className="pe-3"
                        type="text"
                        name="description"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.description}
                        placeholder="e.g: This is a very limited item"
                        rows={4}
                      />
                      <span
                        className="mt-1 d-block"
                        size={"small"}
                        style={{ color: "red" }}
                      >
                        {errors.description && touched.description ? (
                          <Fragment>{errors.description}</Fragment>
                        ) : null}
                      </span>
                    </div>

                    <div className="mb-5">
                      <div className={Classes.label}>Royalty fee %*</div>
                      <input
                        className="pe-3"
                        type="text"
                        name="royaltyFee"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        onKeyDown={(e) => decimalnumbervalidator(e, true, 2)}
                        value={values?.royaltyFee}
                        placeholder="e.g: 3"
                      />
                      <span
                        className="mt-1 d-block"
                        size={"small"}
                        style={{ color: "red" }}
                      >
                        {royaltyFeeValidation ? (
                          <Fragment>{royaltyFeeValidation}</Fragment>
                        ) : null}
                        {errors.royaltyFee && touched.royaltyFee ? (
                          <Fragment>{errors.royaltyFee}</Fragment>
                        ) : null}
                      </span>
                    </div>
                    <div className="mb-5">
                      <div
                        className={`${Classes.label} mb-2 d-flex align-items-center gap-1`}
                      >
                        Hash tags{" "}
                        <p className={`${Classes.desc} mb-0`}>
                          (e.g. type #scholarship and press enter)
                        </p>
                      </div>

                      <div className={Classes.hashTag}>
                        <CreatableSelect
                          components={components}
                          inputValue={inputValue}
                          isClearable
                          isMulti
                          menuIsOpen={false}
                          onChange={(newValue) => setHashTags(newValue)}
                          onInputChange={(newValue) => setInputValue(newValue)}
                          onKeyDown={handleKeyDown}
                          placeholder="Type hash tags and press enter..."
                          value={hashTags}
                        />
                      </div>
                    </div>
                    <div className={Classes.footer}>
                      <Container>
                        <div className={Classes.btnOuter}>
                          <button
                            // onClick={showModal}
                            className={Classes.blueBtn}
                            type="submit"
                            disabled={isLoading}
                          >
                            {isLoading ? (
                              <div className="d-flex justify-content-center align-items-center">
                                <ThreeDots
                                  height="30"
                                  width="30"
                                  radius="9"
                                  color="white"
                                  ariaLabel="three-dots-loading"
                                  wrapperStyle={{}}
                                  wrapperClassName=""
                                  visible={true}
                                />
                              </div>
                            ) : (
                              "Mint"
                            )}
                          </button>
                        </div>
                      </Container>
                    </div>
                  </form>
                </div>
              )}
            </Formik>
            <CreateNftModal
              isModalOpen={isModalOpen}
              createdNft={createdNft}
              handleCancel={handleCancel}
            />
          </Col>
        </Row>
      </Container>
      {showAlertMessage && (
        <NotificationAlert
          title="Transaction Denied"
          content={showAlertMessage}
        />
      )}
      
      <CommonModal
        showModal={showNftRestrictModal}
        isModalOpen={isNftRestrictModalOpen}
        handleCancel={handleNftRestrictModalCancel}
        message={nftRestrictMessage}
        modalType={modalType}
      />
      
    </div>
  );
};

export default CreateNftComp;
